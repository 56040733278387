import './index.css'
import 'element-plus/dist/index.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from './i18n'
import('preline')

import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faPhone,
  faFax,
  faEnvelopeOpen,
  faEnvelopeCircleCheck,
  faUser,
  faPen,
  faFilePdf,
  faPaperclip,
  faGear,
  faSearch,
  faCalendar,
  faClock,
  faPeopleGroup,
  faChalkboardTeacher,
  faLocationDot,
  faGlobe,
  faEuroSign,
  faTag,
  faCartShopping,
  faTrash,
  faAngleLeft,
  faCircleCheck,
  faHashtag,
  faMinusCircle,
  faPlusCircle,
  faCopy
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faPhone,
  faFax,
  faEnvelopeOpen,
  faEnvelopeCircleCheck,
  faUser,
  faPen,
  faFilePdf,
  faPaperclip,
  faGear,
  faSearch,
  faCalendar,
  faClock,
  faPeopleGroup,
  faChalkboardTeacher,
  faLocationDot,
  faGlobe,
  faEuroSign,
  faTag,
  faCartShopping,
  faTrash,
  faAngleLeft,
  faCircleCheck,
  faHashtag,
  faMinusCircle,
  faPlusCircle,
  faCopy
)

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
