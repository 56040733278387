<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useCourseStore } from '@/stores/courseStore';
import vMenuVue from './vMenu.vue';
import { menuStore } from '@/stores/menuStore';
import { useRoute } from 'vue-router';
import { Back } from '@element-plus/icons-vue';

const route = useRoute();
const courseStore = useCourseStore();
const mStore = menuStore();

onMounted(async () => {
    // Launch courseList just for preload. No await
    courseStore.getCourses(false);
    await load();
});

watch(() => route.query.main ?? '', async () => {
    await load();
});

const load = async () => {
    await mStore.loadContent("mainMenu");
    mStore.loadSubMenu("mainMenu", route.query.main as string, "mainMenuSub");
}
</script>

<template>
    <!-- Projects grid -->
    <div class="w-full" v-if="mStore.menu && mStore.menu['mainMenuSub']">
        <RouterLink :to="{ name: 'home' }"><el-icon size="12"><Back /></el-icon> Torna al menu principale</RouterLink>
        <h2 class="mt-3">{{ mStore.menu["mainMenuSub"].title }}</h2>
        <span v-html="mStore.menu['mainMenuSub'].description"></span>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
            <vMenuVue id="mainMenuSub" :noload="true" class="flex-auto flex w-64" layout="cards"></vMenuVue>
        </div>

    </div>
    <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
        <vMenuVue id="mainMenu" class="flex-auto flex w-64" layout="cards"></vMenuVue>
    </div>
</template>
