<script setup lang="ts">
import { AxiosError } from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/stores/loginStore';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';

const username = ref("");
const router = useRouter()
const { t } = useI18n();

const loginStore = useLoginStore();

const reset = async () => {
  try {
    await loginStore.sendPwdReset(username.value, t("key"));
    ElMessage({
      message: t("login.passwordResetOk"),
      type: 'success',
    })
    router.push("/");
    return true;
  }
  catch (e) {
    const err = e as AxiosError;
    ElMessage({
      message: err.message,
      type: 'error',
    })
  }
}
</script>

<template>
  <main class="w-full max-w-2xl mx-auto p-6">
    <div class="mt-7 border border-gray-200 rounded-xl shadow-sm">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800">{{ $t("login.resendPassword") }}</h1>
          <p class="mt-2 text-sm text-gray-600">
            {{ $t("login.haveAccount") }}
            <RouterLink :to="{ name: 'login' }" class="text-red-700 decoration-2 hover:underline font-medium">
              {{ $t("login.signin") }}
            </RouterLink>
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <form>
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label for="email" class="block text-sm mb-2">{{ $t("login.email") }}</label>
                <input type="email" v-model="username" id="email" name="email"
                  class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500"
                  required aria-describedby="email-error">
              </div>
              <!-- End Form Group -->

              <button type="button" @click="reset"
                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-800 text-white hover:bg-red-900  transition-all text-sm ">{{
                  $t("login.reset") }}</button>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>

