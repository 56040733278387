<script setup lang="ts">
import { onMounted, onUnmounted, ref, reactive } from 'vue';
import config from '@/config/config';
import { useI18n } from 'vue-i18n';
import { useLoginStore } from '@/stores/loginStore';
import { useCartStore } from '@/stores/cartStore';
import { useUserContentStore } from '@/stores/userContentStore';
import { DArrowRight, DArrowLeft, Money, Select } from '@element-plus/icons-vue';
import type { RegisterFormPartecipant } from '@/models/registerFormPartecipant';
import type { FormInstance, FormRules } from 'element-plus';
import { ElLoading, ElMessage } from 'element-plus';
import type { RegisterFormCompany } from '@/models/registerFormCompany';
import { loadScript, type CreateOrderData, type OnApproveActions, type OnApproveData, type CreateOrderActions } from "@paypal/paypal-js";
import { PaymentType } from '@/models/PaymentType';
import moment from 'moment';
import type { CartOrder } from '@/models/cartOrder';
import type { CartItem } from '@/models/cartItem';
import axios, { AxiosError } from 'axios';

const lStore = useLoginStore();
const cartStore = useCartStore();
const userContentStore = useUserContentStore();
const activeStep = ref(0);
const orderClosed = ref(false);
const { t } = useI18n();
const savePartecipant = ref(true);
const saveCompany = ref(true);
const paypalLaoding = ref(true);
const stepOrder = ref("horizontal");

const company = ref({} as RegisterFormCompany);
const partecipant = ref({
    email: lStore.eMail,
    name: lStore.name
} as RegisterFormPartecipant);

onMounted(async () => {
    try {
        if (cartStore.items.length > activeStep.value && cartStore.items[activeStep.value].partecipant)
            partecipant.value = cartStore.items[activeStep.value].partecipant as RegisterFormPartecipant;
        await userContentStore.load();
    } catch (e) {
        console.log("Error in onMount", e);
    }
    if (!cartStore.header?.orderId)
        cartStore.header = {
            orderId: moment().format("YYYYMMDD") + "-" + makeid(4),
            orderDate: new Date()
        };
        window.addEventListener("resize", setStepOrder);
        setStepOrder();
});
onUnmounted(() => {
  window.removeEventListener("resize", setStepOrder);
});


const setStepOrder = () => {
            stepOrder.value = window.innerWidth<1024 ? "vertical": "horizontal"; 
        };
const makeid = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const previouseStep = () => {
    activeStep.value = activeStep.value - 1;
    if (activeStep.value < cartStore.totalItems) {
        if (cartStore.items[activeStep.value]?.partecipant)
            partecipant.value = JSON.parse(JSON.stringify(cartStore.items[activeStep.value].partecipant)) as RegisterFormPartecipant;
    }
}

const nextStep = async () => {
    if (activeStep.value < cartStore.totalItems) {
        await ruleFormRef.value?.validate((valid) => {
            if (valid) {
                partecipant.value.fiscalCode = partecipant.value.fiscalCode?.toUpperCase();
                if (savePartecipant.value) {
                    const x = userContentStore.content.partecipants.find(f =>
                        f.name == partecipant.value.name &&
                        f.email == partecipant.value.email &&
                        f.fiscalCode == partecipant.value.fiscalCode);
                    console.log("x", x);
                    if (!x) {
                        userContentStore.content.partecipants.push(JSON.parse(JSON.stringify(partecipant.value)));
                        userContentStore.save();
                    }
                }

                if (activeStep.value < cartStore.totalItems) {
                    cartStore.items[activeStep.value].partecipant = JSON.parse(JSON.stringify(partecipant.value));
                }
                activeStep.value = activeStep.value + 1;
                if (cartStore.items[activeStep.value]?.partecipant)
                    partecipant.value = cartStore.items[activeStep.value].partecipant as RegisterFormPartecipant;
            }
        });
    } else if (activeStep.value == cartStore.totalItems) {
        await ruleregisterFormRef.value?.validate((valid) => {
            if (valid) {
                if (saveCompany.value) {
                    const x = userContentStore.content.companies.find(f =>
                        f.name == company.value.name &&
                        f.address == company.value.address &&
                        f.codUnico == company.value.codUnico &&
                        f.nation == company.value.nation &&
                        f.piva == company.value.piva &&
                        f.codfis == company.value.codfis &&
                        f.zip == company.value.zip &&
                        f.city == company.value.city);
                    console.log("x", x, company);
                    if (!x) {
                        userContentStore.content.companies.push(JSON.parse(JSON.stringify(company.value)));
                        userContentStore.save();
                    }
                }
                cartStore.header.company = company.value;
                activeStep.value = activeStep.value + 1;
                if(cartStore?.totalPrice>0)
                    loadPaypal();
            }
        });
    }
}

const sendOrder = async (closing: boolean) => {
    try {
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });
        const res = await axios.post<CartOrder>(config().BASEURL + config().SAVEORDER + t("key"), {
            header: cartStore.header,
            items: cartStore.items
        }, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
        console.log("CartOrder saved", res.data);
        if (closing)
            sendOrderClose(loading);
        return loading;
    }
    catch (e) {
        const err = e as AxiosError;
        if (err?.response?.data) {
            ElMessage({
                message: t(JSON.stringify(err?.response?.data)),
                type: 'error',
            })
        } else {
            ElMessage({
                message: err.message,
                type: 'error',
            })
        }
    }
}

const sendOrderClose = (loading: any) => {
    cartStore.clear();
    activeStep.value = activeStep.value + 1;
    orderClosed.value = true;
    loading.close();
    return true;
}

const loadPaypal = () => {
    loadScript({
        // Test: clientId: "ATseAKaHr3e2v8D4rsIFMuF1fl1DjmXzybB2sjHe7jlUSKGQzX3hoiTPqJjoldike8T6HWMIjuOMKR23",
        clientId: "AQYCvH1zt2FtqPeR5K4UPAgC-wlkGXFxz2MJUNi89gxX4c8W_vPA20zNmp2gpH6Y1sFr4UyG-DzKcLNI",
        currency: "EUR",
        dataPageType: "checkout",
        disableFunding: "sofort,mybank",
        locale: t("paypalLocale")
    }).then((paypal) => {
        if (paypal?.Buttons) {
            paypal.Buttons({
                createOrder: createOrder,
                onApprove: onApprove,
                onClick: buttonclick,
                style: {
                    color: 'white',
                }
            }).render("#paypalDiv")
                .then(() => {
                    paypalLaoding.value = false;
                })
                .catch((error: any) => {
                    console.error("failed to render the PayPal Buttons", error);
                });
        }
    })
        .catch((error) => {
            console.error("failed to load the PayPal JS SDK script", error);
        });
}

const zeroPay = async () => {
    buttonclick({ fundingSource: 'zero' });
    await sendOrder(true);
}
const buttonclick = (data: Record<string, unknown>) => {
    switch (data.fundingSource as string) {
        case "bank":
            cartStore.header.paymentType = PaymentType.BANKTRANSFER;
            cartStore.header.paymentDetail = {};
            break;
        case "zero":
            cartStore.header.paymentType = PaymentType.ZERO;
            cartStore.header.paymentDetail = {};
            break;
        case "card":
            cartStore.header.paymentType = PaymentType.CREDITCARD;
            break;
        case "paypal":
            cartStore.header.paymentType = PaymentType.PAYPAL;
            break;
    }
}

const createOrder = (data: CreateOrderData, actions: CreateOrderActions) => {
    console.log('Creating order...');
    return actions.order.create({
        purchase_units: [
            {
                reference_id: cartStore.header.orderId,
                amount: {
                    value: cartStore?.totalPrice?.toString(),
                    currency_code: "EUR",
                },
            },
        ],
    });
}

const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    console.log('Order approved...');
    cartStore.header.paymentDetail = data;
    const loading = await sendOrder(false);
    console.log('Order complete!');
    await actions?.order?.capture().then((cap) => {
        console.log("capture", cap);
        sendOrderClose(loading);
    });
}

const setPartecipant = (y: RegisterFormPartecipant) => {
    const x = JSON.parse(JSON.stringify(y));
    partecipant.value.name = x.name;
    partecipant.value.email = x.email;
    partecipant.value.category = x.category;
    partecipant.value.fiscalCode = x.fiscalCode;
    ruleFormRef.value?.validate();
}

const setCompany = (y: RegisterFormCompany) => {
    const x = JSON.parse(JSON.stringify(y));
    company.value.name = x.name;
    company.value.address = x.address;
    company.value.city = x.city;
    company.value.codUnico = x.codUnico;
    company.value.nation = x.nation;
    company.value.piva = x.piva;
    company.value.codfis = x.codfis;
    company.value.zip = x.zip;
    ruleregisterFormRef.value?.validate();
}

const categories = ["Commercialista", "ConsulenteLavoro", "Dipendente", "Avvocato", "Ospite", "PeritoIndustriale", "Tirocinante"];

const ruleFormRef = ref<FormInstance>();
const ruleregisterFormRef = ref<FormInstance>();
const fiscalRequird = () => {
    return cartStore.items[activeStep.value]?.course?.needsFiscalCode ?? true;
}

const rules = reactive<FormRules<RegisterFormPartecipant>>({
    name: [
        { required: true, message: t('courseregister.nameEmpty'), trigger: 'blur' },
    ],
    email: [
        { required: true, message: t('courseregister.emailEmpty'), trigger: 'blur' },
        { type: 'email', message: t('courseregister.emailWrong'), trigger: ['blur'] }
    ],
    fiscalCode: [
        { required: fiscalRequird(), message: t('courseregister.fiscalCodeEmpty'), trigger: 'blur' },
        { min: 16, max: 16, message: t('courseregister.fiscalCode16'), trigger: 'blur' }
    ],
    category: [
        { required: true, message: t('courseregister.categoryEmpty'), trigger: 'blur' },
    ],
});

const rulesCompany = reactive<FormRules<RegisterFormCompany>>({
    "address": [
        { required: true, message: t('courseregister.company.addressEmpty'), trigger: 'blur' },
    ],
    "name": [
        { required: true, message: t('courseregister.company.nameEmpty'), trigger: 'blur' },
    ],
    "city": [
        { required: true, message: t('courseregister.company.cityEmpty'), trigger: 'blur' },
    ],
    "codUnico": [
        { required: true, message: t('courseregister.company.codUnicoEmpty'), trigger: 'blur' },
    ],
    "piva": [
        { required: true, message: t('courseregister.company.pivaEmpty'), trigger: 'blur' },
    ],
    "codfis": [
        { required: true, message: t('courseregister.company.codfisEmpty'), trigger: 'blur' },
    ],
    "zip": [
        { required: true, message: t('courseregister.company.zipEmpty'), trigger: 'blur' },
    ],
    "nation": [
        { required: true, message: t('courseregister.company.nationEmpty'), trigger: 'blur' },
    ],
});

const stepDescription = (itm: CartItem) => {
    let text = itm.course.title[t('key')];
    if (itm.price.description[t('key')]) {
        text = text + ' - ' + itm.price.description[t('key')].replace(/(<([^>]+)>)/gi, ' ');
    }
    return text;
}
</script>

<template>
    <article class="max-w-[85rem] py-10 px-1 lg:px-8 lg:pt-20 mx-auto">
        <h1 class="text-3xl font-bold my-10">{{ $t('courseregister.title') }}</h1>

        <el-steps :active="activeStep" :direction="stepOrder" finish-status="success" align-center class="mb-10">
            <template v-for="(itm, ind) in cartStore.items" :key="ind">
                <el-step :title="$t('courseregister.partecipants')" v-for="idx in itm.quantity" :key="idx"
                    :description="stepDescription(itm)" />
            </template>
            <el-step :title="$t('courseregister.company.title')" />
            <el-step :title="$t('courseregister.payment')" />
            <el-step :title="$t('courseregister.success')" />
        </el-steps>
        <div class="" v-if="activeStep < cartStore.totalItems">
            <div>
                <div class="flex flex-row rounded-md border font-medium text-gray-100 bg-gray-600 shadow-sm p-2 mb-8">
                    <div class="text-xl mb-4 basis-2/3" v-html="cartStore.items[activeStep]?.course?.title[$t('key')]" />
                    <div class="text-xl mb-4 ml-auto text-right">
                        {{ cartStore.items[activeStep]?.price?.price }} <font-awesome-icon icon="euro-sign" />
                        <div class="text-sm"><span
                                v-html="cartStore.items[activeStep]?.price?.description[$t('key')]"></span></div>
                    </div>
                </div>
                <div class="flex flex-col-reverse lg:flex-row">
                    <el-form ref="ruleFormRef" :rules="rules" :model="partecipant" label-width="150px" :status-icon="true"
                        label-position="top" class="max-w-3xl">
                        <div class="flex flex-row">
                            <el-form-item :label="$t('courseregister.name')" prop="name">
                                <el-input v-model="partecipant.name" />
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item :label="$t('courseregister.email')" prop="email">
                                <el-input v-model="partecipant.email" />
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item :label="$t('courseregister.category')" prop="category">
                                <el-select style="width: 350px" multiple v-model="partecipant.category"
                                    :placeholder="$t('courseregister.category')">
                                    <el-option v-for="o in categories" v-bind:key="o"
                                        :label="$t('courseregister.categories.' + o)" :value="o"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item :label="$t('courseregister.fiscalCode')" prop="fiscalCode">
                                <el-input v-model="partecipant.fiscalCode" />
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item :label="$t('courseregister.savePartecipant')">
                                <el-switch v-model="savePartecipant" />
                            </el-form-item>
                        </div>
                    </el-form>
                    <div class="lg:w-full lg:ml-20"
                        v-if="userContentStore.content.partecipants && userContentStore.content.partecipants.length">
                        <div>{{ $t("courseregister.savedPartecipant") }}</div>
                        <div class="flex flex-row flex-wrap rounded-xl items-start bg-white px-2 py-2 h-full">
                            <!-- Card -->
                            <a v-for="(x, idx) in userContentStore.content.partecipants" :key="idx"
                                @click="setPartecipant(x)"
                                class="group flex flex-col bg-gray-100 border shadow-sm rounded-xl hover:shadow-md transition mb-5 mr-1 lg:mr-5">
                                <div class="p-1 lg:p-3">
                                    <div class="flex justify-between items-center">
                                        <div class="pr-3 hidden lg:block">
                                            <font-awesome-icon icon="angle-left" />
                                        </div>
                                        <div>
                                            <h3
                                                class="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                                                {{ x.name }}
                                            </h3>
                                            <p class="text-xs text-gray-500">
                                                {{ x.email }}
                                            </p>
                                            <p class="text-xs text-gray-500">
                                                {{ (x.category || []).join(",") }}
                                            </p>
                                            <p class="text-xs text-gray-500">
                                                {{ x.fiscalCode }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <!-- End Card -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <el-button @click="previouseStep()" size="large">{{ $t('courseregister.previous') }} <el-icon
                        class="el-icon--right">
                        <DArrowLeft />
                    </el-icon>
                </el-button>
                <el-button @click="nextStep()" size="large">{{ $t('courseregister.next') }} <el-icon class="el-icon--right">
                        <DArrowRight />
                    </el-icon>
                </el-button>
            </div>
        </div>

        <div v-if="activeStep == cartStore.totalItems">
            <div class="flex flex-col-reverse lg:flex-row">
                <el-form :inline="true" ref="ruleregisterFormRef" :rules="rulesCompany" :model="company" label-width="150px"
                    :status-icon="true" label-position="top" class="max-w-3xl">
                    <el-form-item :label="$t('courseregister.company.name')" prop="name">
                        <el-input v-model="company.name" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.address')" prop="address">
                        <el-input v-model="company.address" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.zip')" prop="zip">
                        <el-input v-model="company.zip" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.city')" prop="city">
                        <el-input v-model="company.city" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.nation')" prop="nation">
                        <el-input v-model="company.nation" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.piva')" prop="piva">
                        <el-input v-model="company.piva" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.codfis')" prop="codfis">
                        <el-input v-model="company.codfis" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.company.codUnico')" prop="codUnico">
                        <el-input v-model="company.codUnico" />
                    </el-form-item>
                    <el-form-item :label="$t('courseregister.savecompany')">
                        <el-switch v-model="saveCompany" />
                    </el-form-item>
                </el-form>
                <div class="lg:w-full lg:ml-20 mb-5"
                    v-if="userContentStore.content.companies && userContentStore.content.companies.length">
                    <div>{{ $t("courseregister.savedCompanies") }}</div>
                    <div class="flex flex-row flex-wrap items-start rounded-xl bg-white px-2 py-2 h-full">
                        <!-- Card -->
                        <a v-for="(x, idx) in userContentStore.content.companies" :key="idx" @click="setCompany(x)"
                            class="group flex flex-col bg-gray-100 lg:w-48 border shadow-sm rounded-xl hover:shadow-md transition mb-1 lg:mb-5 mr-1 lg:mr-5">
                            <div class="p-1 lg:p-3">
                                <div class="flex  items-center">
                                    <div class="pr-3 hidden lg:block">
                                        <font-awesome-icon icon="angle-left" />
                                    </div>
                                    <div>
                                        <h3
                                            class="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                                            {{ x.name }}
                                        </h3>
                                        <p class="text-xs text-gray-500">
                                            {{ x.address }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{ x.zip }} {{ x.city }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{ x.piva }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{ x.codfis }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{ x.codUnico }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <!-- End Card -->
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <el-button :icon="DArrowLeft" @click="previouseStep()" size="large">{{ $t('courseregister.previous')
                }}</el-button>
                <el-button @click="nextStep()" size="large">{{ $t('courseregister.next') }} <el-icon class="el-icon--right">
                        <DArrowRight />
                    </el-icon>
                </el-button>
            </div>
        </div>
        <div v-if="activeStep == (cartStore.totalItems + 1) && cartStore?.totalPrice!=0" class="m-auto max-w-md">
            <button type="button" @click="buttonclick({ fundingSource: 'bank' });"
                class="border w-full rounded border-gray-600 bg-white hover:bg-gray- py-2 mb-3 flex items-center justify-center">
                <div class="m-auto text-lg"><span class="text-2xl mr-2 mt-2"><el-icon>
                            <Money />
                        </el-icon></span>{{ $t("courseregister.banktransferButton") }}</div>
            </button>
            <div v-if="cartStore.header.paymentType == PaymentType.BANKTRANSFER" class="w-full"><button type="button"
                    class="text-2xl w-4 float-right" @click="cartStore.header.paymentType = undefined">X</button>
                <div class="text-center pt-8 pb-8"
                    v-html="$t('courseregister.banktransfer', { order: cartStore.header.orderId, })" />
                <button
                    class="block mx-auto py-3 px-4 py-3 px-4 justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm"
                    @click="sendOrder(true)" size="large">{{ $t('courseregister.confirm') }} <el-icon
                        class="el-icon--right">
                        <Select />
                    </el-icon>
                </button>
            </div>
            <div v-loading="paypalLaoding" id="paypalDiv" class="m-auto basis-96"
                :style="[cartStore.header.paymentType == PaymentType.BANKTRANSFER ? { display: 'none' } : {}]">
            </div>
        </div>
        <div v-if="activeStep == (cartStore.totalItems + 1) && cartStore?.totalPrice==0" class="m-auto max-w-md">
            <div class="w-full">
                <div class="text-center pt-8 pb-8"
                    v-html="$t('courseregister.zeroPayment')" />
                <button
                    class="block mx-auto py-3 px-4 py-3 px-4 justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm"
                    @click="zeroPay()" size="large">{{ $t('courseregister.confirm') }} <el-icon
                        class="el-icon--right">
                        <Select />
                    </el-icon>
                </button>
            </div>
        </div>
        <div v-if="orderClosed" class="flex">
            <div class="text-center m-auto">
                <font-awesome-icon icon="circle-check" class="text-green-500 text-6xl mb-10" />
                <p>{{ $t("checkout.thanks") }}</p>
                <p>{{ $t("checkout.thanksDetail") }}</p>
                <p class="mt-5">{{ $t("checkout.thanksGreetings") }}</p>
            </div>
        </div>
    </article>
</template>

<style scoped>
.el-input {
    width: 350px;
}
</style>
<style>
.el-form-item__label {
    margin-bottom: 0 !important;
}
</style>