<script setup lang="ts">
import { onMounted } from 'vue';

import { useLoginStore } from '@/stores/loginStore';
import { menuStore } from '@/stores/menuStore';
import { Destinations } from '@/models/destinationsEnum';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import type { MenuSingle } from '@/models/menuSingle';

const { t } = useI18n();
const router = useRouter();

const lStore = useLoginStore();
const mStore = menuStore();

onMounted(async () => {
    try {
        if(!props.noload)
            await mStore.loadContent(props.id);
    } catch {
        ;
    }
});

const preAuth = (menu: MenuSingle) => {
    console.log(menu);
        if(menu && menu.loginMandatory){
            if(!lStore.isLoggedIn){
                router.push({name: "login"});
                return false;
            }
        }
}

const props = defineProps(['id', 'layout', 'noload', 'class'])

</script>

<template>
    <template v-if="props.layout == 'cards'">
        <RouterLink v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive && !props.noload" class="col-span-3"
            :to="{ name: 'menuEditor', params: { menu: props.id } }">
            <font-awesome-icon :icon="['fas', 'gear']" />
        </RouterLink>
        <template v-if="mStore.menu[props.id]">
            <template v-for="(sub) in mStore.menu[props.id].subMenu.filter(f=>f.show)" :key="sub.id"> 
                <single-element :image="sub.imageUrl" v-if="sub.destination.type == Destinations.Content && sub.destination.link" @click="preAuth(sub)"
                    :href="{ name: 'content', params: { content: sub.destination.link, lang: t('key') } }">
                    <p class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2" v-html="sub.title" />
                </single-element>
                <single-element :image="sub.imageUrl" v-if="sub.destination.type == Destinations.Courses" @click="preAuth(sub)"
                    :href="{ name: 'courseDetail', params: { course: sub.destination.link, lang: t('key') } }">
                    <p class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2" v-html="sub.title"/>
                </single-element>
                <single-element :image="sub.imageUrl" v-if="sub.destination.type == Destinations.CourseCategory" @click="preAuth(sub)"
                    :href="{ name: 'home', query: { cat: sub.destination.link } }">
                    <p class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2" v-html="sub.title" />
                </single-element>
                <single-element :image="sub.imageUrl" v-if="sub.destination.type == Destinations.MainPageSubmenu" @click="preAuth(sub)"
                    :href="{ name: 'home', query: { main: sub.title } }">
                    <p class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2" v-html="sub.title" />
                </single-element>
            </template>
        </template>
    </template>
    <template v-else>
        <div v-if="mStore.menu[props.id] && mStore.menu[props.id].show" :id="mStore.menu[props.id].title" class="vmenu" :class="props.class">
            <RouterLink v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive"
                :to="{ name: 'menuEditor', params: { menu: props.id } }">
                <font-awesome-icon :icon="['fas', 'gear']" />
            </RouterLink>
            <span v-html="mStore.menu[props.id].title" class="topLevel"></span>
            <div v-for="sub in mStore.menu[props.id].subMenu.filter(f=>f.show)" v-bind:key="sub.id" class="firstLevel">
                <span v-html="sub.title" />
                <div v-for="sub2 in sub.subMenu.filter(f=>f.show)" v-bind:key="sub2.id" class="secondLevel">
                    <RouterLink v-if="sub2.destination && sub2.destination.type == Destinations.Content && sub2.destination.link" @click="preAuth(sub2)"
                        :to="{ name: 'content', params: { content: sub2.destination.link, lang: t('key') } }">
                        <span v-html="sub2.title" />
                    </RouterLink>
                    <RouterLink v-if="sub2.destination && sub2.destination.type == Destinations.Courses && sub2.destination.link" @click="preAuth(sub2)"
                        :to="{ name: 'courseDetail', params: { course: sub2.destination.link, lang: t('key') } }">
                        <span v-html="sub2.title" />
                    </RouterLink>
                    <RouterLink v-if="sub2.destination && sub2.destination.type == Destinations.CourseCategory && sub2.destination.link" @click="preAuth(sub2)"
                        :to="{ name: 'home', query: { cat: sub2.destination.link } }">
                        <span v-html="sub2.title" />
                    </RouterLink>
                    <div v-for="sub3 in sub2.subMenu.filter(f=>f.show)" v-bind:key="sub3.id" class="thidLevel">
                        <span v-html="sub3.title" />
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<style></style>
