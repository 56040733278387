<template>
    <div class="box-card w-100 m-20">
      <el-card>
        <template #header>
          <h2>{{ $t('login.resetlinktitle') }}</h2>
          <div class="desc" v-html="$t('login.resetdescription')"></div>
        </template>
        <el-form class="login-form" label-position="top" ref="form" @submit.prevent="chgpassword">
          <el-form-item prop="pass" :label="$t('login.password1')">
            <el-input
              v-model="password1"
              :placeholder="$t('login.password1')"
              prefix-icon="lock"
              show-password
            ></el-input>
            <div v-if="v$.password1.$invalid" class="invalid-feedback left">
              <span v-if="password1 && v$.password1.minLength.$invalid">{{ $t('login.password.minlength') }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="pass" :label="$t('login.password2')">
            <el-input
              v-model="password2"
              :placeholder="$t('login.password2')"
              prefix-icon="lock"
              show-password
            ></el-input>
            <div v-if="!v$.password1.$invalid && v$.password2.sameAsPassword.$invalid" class="invalid-feedback left">
              <span>{{ $t('login.password.sameAsPassword') }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" :disabled="v$.$invalid" class="login-button" type="primary" native-type="submit"
              block>{{ $t('login.savepassword') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <style>
  .el-date-editor.el-input,
  .el-date-editor.el-input__wrapper {
    width: 100% !important;
  }
  
  div.desc {
    margin: 15px;
  }
  </style>
  
  <script lang="ts">
  
  import { defineComponent } from 'vue';
  import useValidate from '@vuelidate/core';
  import { minLength, required, sameAs } from '@vuelidate/validators';
  import { ElMessage } from 'element-plus'
  import axios from 'axios';
  import config from '@/config/config'
  
  import router from '@/router';
  import moment from 'moment';
  
  export default defineComponent({
    name: 'ForgotPasswordForm',
    /*components: {
      DTPicker,
    },
    mounted(){
    },*/
    data() {
      return {
        v$: useValidate(),
        password1: "",
        password2: "",
        loading: false
      }
    },
    validations() {
      return {
        password1: { required, minLength: minLength(6) },
        password2: { required, sameAsPassword: sameAs(this.password1) }
      }
    },
    methods: {
      getPwd() {
        return btoa(moment().format("YY-MM-DD"));
      },
      async chgpassword() {
        try {
          this.v$.$validate() // checks all inputs
          if (this.v$.$error) {
            this.v$.$errors.forEach((element: any) => {
              ElMessage({
                message: element.$message as string,
                type: 'error',
              });
            });
          }
          this.loading = true;
          await axios.post<string>(config().BASEURL + config().SETPASSWORDURL + this.$route.params?.token , {
            Password: this.password1
          });
  
          this.loading = false;
          ElMessage({
            message: 'Password changed successfully.',
            type: 'success',
          });
          router.push({ name: 'home' })
        } catch (e) {
          this.loading = false;
          ElMessage({
            message: 'Password could not be changed: ' + e,
            type: 'error',
          });
        }
      }
    }
  });
  </script>
  