import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginComp from '@/components/LoginComp.vue'
import MenuEditor from '@/components/admin/menuEditor.vue'
import ContentEditor from '@/components/admin/contentEditor.vue'
import ContentView from '@/components/contentView.vue'
import CourseListAdmin from '@/components/admin/courseListAdmin.vue'
import CourseEditor from '@/components/admin/courseEditor.vue'
import RegisterComp from '@/components/RegisterComp.vue'
import HeroList from '@/components/admin/heroList.vue'
import TrainerListAdmin from '@/components/admin/trainerListAdmin.vue'
import TrainerEditor from '@/components/admin/trainerEditor.vue'
import CourseCategoryListAdmin from '@/components/admin/courseCategoryListAdmin.vue'
import CourseCategoryEditor from '@/components/admin/courseCategoryEditor.vue'
import partecipantListAdmin from '@/components/admin/partecipantListAdmin.vue'
import CourseDetail from '@/components/courseDetail.vue'
import CartList from '@/components/cartList.vue'
import CheckOut from '@/components/checkOut.vue'
import UserList from '@/components/admin/userList.vue'
import ResetPassword from '@/components/resetPassword.vue'
import CompanyListUserAdmin from '@/components/userAdmin/companyListUserAdmin.vue'
import PartecipantListUserAdmin from '@/components/userAdmin/partecipantListUserAdmin.vue'
import UserSettings from '@/components/userAdmin/UserSettings.vue'
import indexUserAdmin from '@/components/userAdmin/indexUserAdmin.vue'
import indexAdmin from '@/components/admin/indexAdmin.vue'
import ResendPassword from '@/components/resendPassword.vue'
import TrainerDetail from '@/components/trainerDetail.vue'
import OrdersListUserAdmin from '@/components/userAdmin/ordersListUserAdmin.vue'
import OrdersListAdmin from '@/components/admin/ordersListAdmin.vue'
import jwt_decode from 'jwt-decode'
import type { Token } from '@/models/token'
import SendMail from '@/components/admin/sendMail.vue'

const getAuth = () => {
  let isLoggedIn=false;
  let isLoggedInAdmin=false;

  const localToken = localStorage.getItem('loginToken')
  if(localToken){
    try{
      const decoded = jwt_decode<Token>(localToken)
      if (decoded.role == 'Admin') isLoggedInAdmin = true
      isLoggedIn = !!decoded.Id
    }
    catch(e){
      console.error(e);
    }
  }

  return {isLoggedIn, isLoggedInAdmin}
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/admin',
      component: indexAdmin,
      name: 'admin',
      beforeEnter() {
        const store = getAuth();
        if(!store.isLoggedIn)
          return { name: "login"}
        if(!store.isLoggedInAdmin)
          throw Error("Only Admins are authorized");
      },
      children: [
        {
          path: '/menuEditor/:menu',
          name: 'menuEditor',
          component: MenuEditor
        },
        {
          path: '/contentEditor/:content',
          name: 'contentEditor',
          component: ContentEditor
        },
        {
          path: '/courseEditor/list',
          name: 'courseList',
          component: CourseListAdmin
        },
        {
          path: '/courseEditor/edit/:Id',
          name: 'courseEdit',
          component: CourseEditor
        },
        {
          path: '/heros',
          name: 'heroList',
          component: HeroList
        },
        {
          path: '/trainer/list',
          name: 'trainerList',
          component: TrainerListAdmin
        },
        {
          path: '/trainer/edit/:Id',
          name: 'trainerEdit',
          component: TrainerEditor
        },
        {
          path: '/category/list',
          name: 'categoryList',
          component: CourseCategoryListAdmin
        },
        {
          path: '/category/edit/:Id',
          name: 'categoryEdit',
          component: CourseCategoryEditor
        },
        {
          path: '/users',
          name: 'userList',
          component: UserList
        },
        {
          path: '/orders',
          name: 'orderList',
          component: OrdersListAdmin
        },
        {
          path: '/partecipants',
          name: 'partecipantsList',
          component: partecipantListAdmin
        },
        {
          path: '/sendmail/:courseId',
          name: 'sendMail',
          component: SendMail
        }
        
      ]
    },
    {
      path: '/user',
      component: indexUserAdmin,
      name: 'userAdmin',
      beforeEnter() {
        const store = getAuth();
        if(!store.isLoggedIn)
          return { name: "login"}
      },
      children: [
        {
          path: 'companies',
          name: 'user-companies',
          component: CompanyListUserAdmin
        },
        {
          path: 'partecipant',
          name: 'user-partecipant',
          component: PartecipantListUserAdmin
        },
        {
          path: 'orders',
          name: 'user-orders',
          component: OrdersListUserAdmin
        },
        {
          path: 'settings',
          name: 'user-settings',
          component: UserSettings
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: LoginComp
    },
    {
      path: '/signup',
      name: 'register',
      component: RegisterComp
    },
    {
      path: '/:lang/:content',
      name: 'content',
      component: ContentView
    },
    {
      path: '/:lang/course/:course/:pwd',
      name: 'courseDetailSec',
      component: CourseDetail
    },
    {
      path: '/:lang/course/:course',
      name: 'courseDetail',
      component: CourseDetail
    },
    {
      path: '/:lang/checkout',
      name: 'checkout',
      beforeEnter() {
        const store = getAuth();
        if(!store.isLoggedIn)
          return { name: "login"}
      },
      component: CheckOut
    },
    {
      path: '/:lang/cart',
      name: 'cart',
      beforeEnter() {
        const store = getAuth();
        if(!store.isLoggedIn)
          return { name: "login"}
      },
      component: CartList
    },
    {
      path: '/reset/:token',
      name: 'reset',
      component: ResetPassword
    },
    {
      path: '/pwdreset',
      name: 'pwdreset',
      component: ResendPassword
    },
    {
      path: '/speaker/:Id',
      name: 'speaker',
      component: TrainerDetail
    }
  ]
})

export default router
