<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HomeGrid from '@/components/homeGrid.vue';
import CourseListVue from '@/components/courseList.vue';
import { useCourseCategoryStore } from '@/stores/categoryStore';
import { useI18n } from 'vue-i18n';

const categoryStore = useCourseCategoryStore();
const route = useRoute();
const router = useRouter();
const searchString = ref("");

const selectedCategory = ref("")
const { t } = useI18n();

onMounted(async () => {
    setRoute();
    await categoryStore.load();
});

const setRoute = () => {
    if (route.query && route.query.cat && route.query.cat!=selectedCategory.value)
       selectedCategory.value = route.query.cat as string;
    if (route.query && route.query.search && route.query.search!=searchString.value)
       searchString.value = route.query.search as string;
}
const setCatRoute = () => {
    router.replace({name: "home", query: {cat: selectedCategory.value}});
}

const setSearchString = () => {
    router.replace({name: "home", query: {...route.query, search: searchString.value}});
}

watch(route, () => setRoute());
</script>

<template>
    <!-- Projects grid -->
    <section class="pt-10 sm:pt-14 max-w-[85rem] px-4 pt-10 sm:px-6 lg:px-8 lg:pt-14 mx-auto">
        <!-- Filter and search projects -->
        <div class="mt-10 sm:mt-10">
            <div class="
					flex
					justify-between
					border-b 
                    border-gray-300
					pb-3
					gap-0
				">
                <div class="flex justify-between gap-3">
                    <span class="
							hidden
							sm:block
                            border
                            border-gray-300
                            bg-gray-400
							p-2
                            px-4
							shadow-sm
							rounded-xl
							cursor-pointer
							">
                        <font-awesome-icon :icon="['fa', 'search']" class="text-gray-100" />
                    </span>
                    <input v-model="searchString" @change="setSearchString" class="font-general-medium
						pl-3
						pr-1
						sm:px-4
						py-2
						border
                        border-gray-300
						rounded-lg
						text-md
                        bg-gray-400
                        text-gray-100
                        placeholder-gray-300
						" style="max-width: 45vw;" id="name" name="name" type="search" required placeholder="Search Courses" aria-label="Name" />
                </div>
                <select v-model="selectedCategory" style="max-width: 45vw;" name="selectedCategory" @change="setCatRoute()" id="selectedCategory" class="font-general-medium
                                                                            px-4
                                                                            py-2
                                                                            border 
                                                                            border-gray-300
                                                                            rounded-lg
                                                                            text-md
                                                                            bg-gray-400
                                                                            text-gray-100
                                                                            leading-none
                                                                                    ">
                    <option value="" class="text-sm sm:text-md">{{ $t("app.allCategories") }}</option>
                    <option v-for="option in categoryStore.categories" :key="option.id" :value="option.id"
                        class="sm:text-md">
                        {{ option.title[t('key')] }}
                    </option>
                </select>
            </div>
        </div>
    </section>
    <HomeGrid v-if="!searchString && !selectedCategory" class="max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 mx-auto pb-14">
    </HomeGrid>

    <CourseListVue v-if="searchString || selectedCategory" class="max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 pb-14 mx-auto"
        :search="searchString" :category="selectedCategory"></CourseListVue></template>
