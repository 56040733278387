<script setup lang="ts">
import axios from 'axios';
import config from '@/config/config';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ElLoading, ElMessage } from 'element-plus';

const { t } = useI18n();

const username = ref("");
const name = ref("");
const mailused = ref(false);
const checked = ref(false);
const router = useRouter()

const register = async () => {
  const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });

  await axios.post<string>(config().BASEURL + config().REGISTER + t("key"), {email: username.value, name: name.value, Roles: []});
  router.push("/");
  loading.close();
  ElMessage({
                message: t("login.registerOkMessage"),
                type: 'success',
            })
  return true;
}

const formValid = ()=>checked.value && name.value!='';

const checkMail = async () => {
  try{
    await axios.get<string>(config().BASEURL + config().CHECKMAIL + username.value);
    mailused.value = false;
    checked.value = true;
  } catch {
    mailused.value = true;
    checked.value = false;
  }
}
</script>

<script lang="ts">

</script>

<template>
    <main class="w-full max-w-2xl mx-auto p-6">
      <div class="mt-7 border border-gray-200 rounded-xl shadow-sm">
        <div class="p-4 sm:p-7">
          <div class="text-center">
            <h1 class="block text-2xl font-bold text-gray-800">{{ $t("login.registertitle") }}</h1>
            <p class="mt-2 text-sm text-gray-600">
              {{ $t("login.haveAccount") }}
              <RouterLink :to="{name: 'login'}" class="text-red-700 decoration-2 hover:underline font-medium">
                {{ $t("login.signin") }}
              </RouterLink>
            </p>
          </div>

          <div class="mt-5">
            <!-- Form -->
            <form>
              <div class="grid gap-y-4">
                <!-- Form Group -->
                <div class="relative">
                  <label for="email" class="block text-sm mb-2">{{ $t("login.email") }}</label>
                    <input type="email" v-model="username" id="email" name="email" @change="checkMail()" :class="{ 'border-red-900': mailused }" class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500" required aria-describedby="email-error">
                    <div class="valid-feedback feedback-icon" v-if="username != ''">
                          <i class="fa fa-check" v-if="!mailused"></i>
                          <i class="fa-solid fa-circle-xmark text-red-900" v-if="mailused"></i>
                    </div>
                    <div class="text-red-900" v-if="mailused">Mail in use</div>
                </div>
                <div class="relative">
                  <label for="name" class="block text-sm mb-2">{{ $t("login.name") }}</label>
                    <input type="string" v-model="name" id="name" name="name" class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500" required aria-describedby="email-error">
                </div>
                <!-- End Form Group -->

                <button type="button" :disabled="!formValid()" @click="register" :class="{'text-white': formValid(), 'text-gray-400': !formValid(), 'hover:bg-red-800': !formValid()}" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-800 hover:bg-red-900  transition-all text-sm ">{{ $t("login.register") }}</button>
              </div>
            </form>
            <!-- End Form -->
          </div>
        </div>
      </div>
    </main>
</template>

<style scoped>
.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
    position: absolute;
    width: auto;
    top: 40px;
    right: 10px;
    margin-top: 0;
}</style>