<template>
    <div class="h-screen py-8 flex flex-col">
        <div class="container mx-auto lg:px-4">
            <h1 class="text-2xl font-semibold mb-4">{{ $t("cart.title") }}</h1>
            <div class="flex flex-col gap-4">
                <div class="md:w-full">
                    <div class="lg:hidden mb-4">
                        <div v-for="itm in cartStore.items" :key="itm.key" class="flex mb-8 bg-white shadow-md">
                            <div>
                                <div class="pl-4">
                                    <span class="font-semibold" v-html="itm.course.title[$t('key')]"></span>
                                </div>
                                <div>
                                    <span class="pl-4 text-left font-semibold">{{ $t("cart.priceTitle") }}:</span>
                                    <span class="pl-4" v-html="itm.price.description[$t('key')]" />
                                </div>
                                <div>
                                    <span class="pl-4 text-left font-semibold">{{ $t("cart.itemPrice")
                                    }}:</span>
                                    <span class="pl-4 whitespace-nowrap text-lg">{{ itm.price.price.toFixed(2)
                                    }} <font-awesome-icon icon="euro-sign" /></span>

                                </div>
                            </div>
                            <div class="basis-16 my-auto">
                                <button type="button" @click="cartStore.removeItem(itm.key)"
                                class="text-red-800 hover:text-red-900 text-2xl mb-3">
                                    <font-awesome-icon icon="trash" />
                                </button><br>
                                <button type="button" class="text-red-800 hover:text-red-900 text-2xl"
                                    @click="cartStore.addItem(itm.course, itm.price)" :title="t('cart.duplica')">
                                    <font-awesome-icon icon="copy" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white hidden lg:block rounded-lg shadow-md lg:p-6 mb-4">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="p-4 text-left font-semibold">{{ $t("cart.courseTitle") }}</th>
                                    <th class="p-4 text-left font-semibold">{{ $t("cart.priceTitle") }}</th>
                                    <th class="p-4 text-left font-semibold text-right pr-20">{{ $t("cart.itemPrice") }}</th>
                                    <th class="p-4 text-left font-semibold"><!--{{ $t("cart.quantity") }}--></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="itm in cartStore.items" :key="itm.key">
                                    <td class="p-4 w-3/5">
                                        <div class="flex items-center">
                                            <img class="h-16 w-24 object-cover mr-4 hidden lg:inline"
                                                :src="itm.course.image" alt="Product image">
                                            <span class="font-semibold" v-html="itm.course.title[$t('key')]"></span>
                                        </div>
                                    </td>
                                    <td class="p-4 w-1/5" v-html="itm.price.description[$t('key')]" />
                                    <td class="p-4 w-1/5 whitespace-nowrap text-right text-xl">{{ itm.price.price.toFixed(2)
                                    }} <font-awesome-icon icon="euro-sign" />
                                        <button type="button" @click="cartStore.removeItem(itm.key)"
                                            class="text-red-800 hover:text-red-900 text-2xl mx-4">
                                            <font-awesome-icon icon="trash" />
                                        </button>
                                    </td>
                                    <td class="p-4 w-1/5">
                                        <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                            <!--                                            <a class="text-red-800 hover:text-red-900 text-2xl mx-4"
                                                @click="cartStore.removeItem(itm.key)">
                                                <font-awesome-icon icon="circle-minus" />
                                            </a>
                                            <span class="text-xl">{{ itm.quantity.toFixed(0) }}</span>-->
                                            <a class="text-red-800 hover:text-red-900 text-2xl mx-4"
                                                @click="cartStore.addItem(itm.course, itm.price)"
                                                :title="t('cart.duplica')">
                                                <font-awesome-icon icon="copy" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <button type="button" @click="$router.push({ name: 'checkout', params: { lang: $t('key') } })"
                    class="grow-0 w-32 self-end ml-10 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm">
                    {{ $t("cart.toCheckout") }}
                </button>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import { useCartStore } from '@/stores/cartStore'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const cartStore = useCartStore();
</script>