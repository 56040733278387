<script setup lang="ts">
import { ref } from 'vue';
import { RouterView } from 'vue-router';
import FooterComp from './components/FooterComp.vue';

import { useLoginStore } from '@/stores/loginStore'
import { useCartStore } from '@/stores/cartStore'
import { useI18n } from 'vue-i18n';
import type { CartItem } from './models/cartItem';
import i18n from './i18n';

const { t } = useI18n();

const store = useLoginStore();
const cartStore = useCartStore();
const cartButton = ref(null as unknown as HTMLElement);
const cartButtonUpdate = ref(false);
const storeItem_old = ref(JSON.parse(JSON.stringify(cartStore.items)) as CartItem[]);
const mobileMenuVisible = ref(false);

cartStore.$subscribe(() => {
  let found = true;
  if (storeItem_old.value.length == cartStore.totalItems) {
    const x = cartStore.items.filter((f, idx) => storeItem_old.value[idx].key != f.key);
    if (!x.length)
      found = false;
  }
  if (found) {
    if (!isInViewport(cartButton.value)) {
      // Something in the store updated
      cartButtonUpdate.value = true;
      setTimeout(() => {
        cartButtonUpdate.value = false;
      }, 3000);
    } else {
      if(cartButton.value)
      {
        cartButton.value.classList.add("animate-bounce");
        setTimeout(() => {
          if(cartButton.value) {
            cartButton.value.classList.remove("animate-bounce");
          }
        }, 3000);
      }
    }
  }
  storeItem_old.value = JSON.parse(JSON.stringify(cartStore.items));
});

function isInViewport(element: HTMLElement) {
  if (!element)
    return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const changeLang = (lang: string) => {
  localStorage.setItem('locale', lang);
  i18n.global.locale.value = lang;
};
</script>

<template>
  <header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-3 sm:py-0">
    <nav
      class="max-w-[85rem] px-4 sm:px-6 lg:px-8  relative w-full mx-auto sm:flex sm:items-center sm:justify-between flex"
      aria-label="Global">
      <div class="flex items-center justify-between">
        <a class="flex-none lg:mt-10" href="/" aria-label="Brand"><img src="@/assets/logo_full.png"
            style="max-width: 50vw;" alt="Logo Koine"></a>
      </div>

      <div id="navbar-collapse-with-animation"
        class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
        <div
          class="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">

          <div class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] ">
            <button type="button" class="flex items-center w-full text-red-800 hover:text-gray-400 font-medium">
              {{ $t("key") }}
              <svg class="ml-2 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round"></path>
              </svg>
            </button>

            <div
              class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
              <a v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" @click="changeLang(locale)"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t(locale) }}</a>
            </div>
          </div>
          <div v-if="store.isLoggedIn && store.isLoggedInAdmin"
            class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
            <button type="button" class="flex items-center w-full text-red-800 hover:text-gray-400 font-medium">
              {{ $t("app.admincenter.title") }}
              <svg class="ml-2 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round"></path>
              </svg>
            </button>

            <div
              class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
              <router-link :to="{ name: 'courseList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.courseList") }}</router-link>
              <router-link :to="{ name: 'heroList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.heroList") }}</router-link>
              <router-link :to="{ name: 'trainerList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.trainerList") }}</router-link>
              <router-link :to="{ name: 'categoryList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.categoryList") }}</router-link>
              <router-link :to="{ name: 'userList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.userlist") }}</router-link>
              <router-link :to="{ name: 'orderList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.orderList") }}</router-link>
              <router-link :to="{ name: 'partecipantsList' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.admincenter.partecipantsList") }}</router-link>
              <a @click="store.toggleAdmintools()"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  store.isAdminToolActive ? t("app.deactivateAdminTools") : t("app.activateAdminTools") }}</a>
            </div>
          </div>
          <div v-if="store.isLoggedIn"
            class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
            <button type="button" class="flex items-center w-full text-red-800 hover:text-gray-400 font-medium">
              {{ store.name }}
              <svg class="ml-2 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round"></path>
              </svg>
            </button>

            <div
              class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
              <router-link :to="{ name: 'user-settings' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.usercenter.settings") }}</router-link>
              <router-link :to="{ name: 'user-partecipant' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.usercenter.partecipants") }}</router-link>
              <router-link :to="{ name: 'user-companies' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.usercenter.company") }}</router-link>
              <router-link :to="{ name: 'user-orders' }"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  $t("app.usercenter.orders") }}</router-link>
              <a @click="store.logout()"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                  t("app.logout") }}</a>
            </div>
          </div>
          <button type="button" @click="$router.push({ name: 'login' })" v-if="!store.isLoggedIn"
            class="py-3 px-4 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm">
            {{ $t("app.login") }}
            <font-awesome-icon :icon="['fas', 'user']" />
          </button>
        </div>
      </div>
      <div>
        <button ref="cartButton" type="button" @click="$router.push({ name: 'cart', params: { lang: t('key') } })"
          v-if="cartStore.items.length"
          class="cart-button ml-5 mt-3 lg:mt-0 py-3 px-4 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm">
          <span class="hidden lg:inline">{{ cartStore.totalItems.toFixed(0) }}</span><span class="hidden lg:inline">
            <font-awesome-icon icon="cart-shopping" /></span> {{
              cartStore.totalPrice.toFixed(2) }} <font-awesome-icon icon="euro-sign" />
        </button>
      </div>
      <div class="lg:hidden ml-auto">
        <button class="navbar-burger flex items-center text-red-800 mt-3 p-3" @click="mobileMenuVisible = true">
          <svg class="block h-6 w-6 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
      <div class="navbar-menu relative z-50" :class="{ hidden: !mobileMenuVisible }">
        <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div class="flex items-center mb-6">
            <button class="navbar-close" @click="mobileMenuVisible = false">
              <svg class="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
<div>          <button type="button" v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" @click="mobileMenuVisible = false; changeLang(locale)"
                class="py-1 px-2 mr-3 mb-6 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-800 text-white hover:bg-red-900  transition-all text-sm ">{{
                  $t(locale) }}</button></div>
          <div v-if="store.isLoggedIn && store.isLoggedInAdmin" class="mb-5">
            <h3>Admin</h3>
            <router-link :to="{ name: 'courseList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.courseList") }}</router-link>
            <router-link :to="{ name: 'heroList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.heroList") }}</router-link>
            <router-link :to="{ name: 'trainerList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.trainerList") }}</router-link>
            <router-link :to="{ name: 'categoryList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.categoryList") }}</router-link>
            <router-link :to="{ name: 'userList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.userlist") }}</router-link>
            <router-link :to="{ name: 'orderList' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.admincenter.orderList") }}</router-link>
            <a @click="mobileMenuVisible = false; store.toggleAdmintools()" 
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                store.isAdminToolActive ? t("app.deactivateAdminTools") : t("app.activateAdminTools") }}</a>
          </div>
          <div v-if="store.isLoggedIn">
            <h3>{{ $t("app.usercenter.title") }}</h3>
            <router-link :to="{ name: 'user-settings' }" mobileMenuVisible=false
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.usercenter.settings") }}</router-link>
            <router-link :to="{ name: 'user-partecipant' }" @click="mobileMenuVisible = false"
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.usercenter.partecipants") }}</router-link>
            <router-link :to="{ name: 'user-companies' }" mobileMenuVisible=false
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.usercenter.company") }}</router-link>
            <router-link :to="{ name: 'user-orders' }" mobileMenuVisible=false
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                $t("app.usercenter.orders") }}</router-link>
            <a @click="store.logout()" mobileMenuVisible=false
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">{{
                t("app.logout") }}</a>
          </div>
          <div v-if="!store.isLoggedIn">
            <button type="button" @click="mobileMenuVisible = false; $router.push({ name: 'login' })"
              class="py-3 px-4 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm">
              {{ $t("app.login") }}
              <font-awesome-icon :icon="['fas', 'user']" />
            </button>
          </div>
        </nav>
      </div>
      <transition type="transition" enter-active-class="transition-all duration-500" enter-from-class="opacity-0 top-0"
        enter-to-class="opacity-100 top-10" leave-active-class="transition-all duration-500"
        leave-from-class="opacity-100" leave-to-class="opacity-0">
        <button type="button" v-if="cartButtonUpdate" @click="$router.push({ name: 'cart', params: { lang: t('key') } })"
          class="fixed cart-button-updated animate-bounce cart-button ml-5 py-3 px-4 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-100 bg-red-800 shadow-sm align-middle hover:bg-red-900 transition-all text-sm">
          {{ cartStore.totalItems.toFixed(0) }} <font-awesome-icon icon="cart-shopping" /> {{
            cartStore.totalPrice.toFixed(2) }} <font-awesome-icon icon="euro-sign" />
        </button>
      </transition>
    </nav>
  </header>
  <RouterView />
  <FooterComp></FooterComp>
</template>

<style>
.cart-button-updated {
  right: calc((100% - 80rem)/2);
}

H1 {
  font-size: 2em;
}

H2 {
  font-size: 1.5em;
}

H3 {
  font-weight: bold;
}
</style>