<script setup lang="ts">
import { useRoute } from 'vue-router';
import VContent from './vContent.vue';

const router = useRoute();

</script>

<template>
    <div class="viewContent sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-3 sm:py-0">
         <VContent :id="router.params.content" class="flex flex-col relative max-w-7xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"></VContent>
    </div>
</template>

<style>
.viewContent {
    margin: 80px 0;
}
</style>