<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useTrainerStore } from '@/stores/teacherStore';
import type { Trainer } from '@/models/trainer';
import { useLoginStore } from '@/stores/loginStore';

const tStore = useTrainerStore();
const route = useRoute();
const trainer = ref({} as Trainer);
const lStore = useLoginStore();

onMounted(async () => {
    try {
        await tStore.load();
        const t = tStore.trainers.find(f => f.id == route.params?.Id);
        if (t)
            trainer.value = t;
    } catch {
        ;
    }
});

</script>

<template>
    <div class="adminTool max-w-[85rem] mx-auto flex lg:flex-row md:flex-col"
        v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive">
        <RouterLink :to="{ name: 'trainerEdit', params: { Id: trainer.id ?? 'x' } }">
            <font-awesome-icon :icon="['fas', 'gear']" />
        </RouterLink>
    </div>
    <article class="vTrainer mt-8 max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
        <h1 class="text-xl mb-5">{{ trainer.name }}</h1>
        <div class="flex flex-row">
            <img :src="trainer.imageUrl" v-if="trainer.imageUrl" class="w-1/3" />
            <div v-html="trainer.description[$t('key')]" v-if="trainer.description" class="ml-5 w-2/3"></div>
        </div>
    </article>
</template>
