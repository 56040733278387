import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config';
import axios from 'axios';
import type { Content } from '@/models/content';
import { useI18n } from 'vue-i18n';

export const contentStore = defineStore('content', () => {
  const { t, locale  } = useI18n();
  const cache = {} as {[id: string] : Content;};
  const content = ref({} as {[id: string] : string;})
  const title = ref({} as {[id: string] : string;})

  const loadContent = async (id: string) =>{ 
    const res = await axios.get<Content>(config().BASEURL + config().CONTENT + id);
    cache[id] = res.data;

    content.value[id] = res.data.body[t("key")];
    title.value[id] = res.data.title[t("key")];
  }

  watch(locale, async () => {
    Object.keys(cache).forEach(id => {
      content.value[id] = cache[id].body[t("key")];
      title.value[id] = cache[id].title[t("key")];  
    });    
  });

  return { loadContent, content, title }
})
