import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config';
import axios from 'axios';
import type { Menu } from '@/models/menu';
import type { MenuSingle } from '@/models/menuSingle';
import { Destinations } from '@/models/destinationsEnum';
import { useI18n } from 'vue-i18n';

export const menuStore = defineStore('menu', () => {
  const cache = {} as {[id: string] : Menu;};
  const menu = ref({} as {[id: string] : MenuSingle})
  const {t, locale} = useI18n();

  const loadContent = async (id: string) =>{ 
    try{
      const res = await axios.get<Menu>(config().BASEURL + config().MENU + id);
      const mdata = res.data;
      cache[id] = mdata;
      menu.value[id] = MenuToSingle(mdata);
    } catch {
      menu.value[id] = {
        description:"",
        destination: {
          type: Destinations.Content,
          link: ""
        },
        publicUrl:"",
        title:"",
        show:false,
        subMenu: [],
        id:id
      }
    }
  }

  const loadSubMenu = async (id: string, title: string, newid: string) => {
    delete menu.value[newid];
    if(menu.value[id]){
      const m = menu.value[id];
      const n = m.subMenu.find(x => x.title == title);
      if(n)
        menu.value[newid] = n;
    }
  }

  watch(locale, () => {
    Object.keys(cache).forEach(id => {
      const m = cache[id];
      menu.value[id] = MenuToSingle(m);
    });
  });

  const MenuToSingle = (m: Menu) : MenuSingle => {
    return {
      description: m.description[t("key")],
      destination: m.destination,
      publicUrl: m.publicUrl[t("key")],
      title: m.title[t("key")],
      id: m.id,
      show: m.show,
      subMenu: m.subMenu.map(x => MenuToSingle(x)),
      imageUrl: m.imageUrl,
      loginMandatory: m.loginMandatory
    } as MenuSingle;
  }
  return { loadContent, menu, loadSubMenu }
})
