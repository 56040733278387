<script setup lang="ts">
import type { Course } from '@/models/course';
import { onMounted } from 'vue';
import { useCourseStore } from '@/stores/courseStore';
import SingleCourseElement from './singleCourseElement.vue';

const courseStore = useCourseStore();

const props = defineProps(["search", "category"]);

onMounted(async () => {
    await courseStore.getCourses(false, new Date());
});

const filterFunction = (o: Course) => {
    if(props.category && (o.categoryId?.indexOf(props.category) ?? -1) == -1)
       return false;
    if(!o.isVisible)
       return false;

    const x = o as any;
    return Object.keys(x).some(function(k) {
        if(x[k]){
            //console.log(JSON.stringify(x[k]));
            return JSON.stringify(x[k]).toLowerCase().indexOf(props.search.toLowerCase()) != -1;
        }
        return false;
    });
}
</script>

<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
        <template v-for="course in courseStore.courses" :key="course.id">
            <single-course-element :course="course" v-if="filterFunction(course)"/>
        </template>
        <div v-if="courseStore.courses.length==0">
            {{ $t("courseList.empty") }}
        </div>
    </div>
</template>