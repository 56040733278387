<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserContentStore } from '@/stores/userContentStore';
import { Delete } from '@element-plus/icons-vue';
import type { RegisterFormCompany } from '@/models/registerFormCompany';

const { t } = useI18n();

const userContentStore = useUserContentStore();

onMounted(async () => {
    userContentStore.load();
});

const deletePart = (row: RegisterFormCompany) => {
    userContentStore.content.companies = userContentStore.content.companies.filter(p => !(
        p.name == row.name &&
        p.address == row.address &&
        p.zip == row.zip &&
        p.city == row.city &&
        p.nation == row.nation &&
        p.piva == row.piva &&
        p.codfis == row.codfis &&
        p.codUnico == row.codUnico));
    userContentStore.save();
}

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("userAdmin.companies.list") }}</div>
            </div>
        </template>
        <el-table :data="userContentStore.content.companies" style="width: 100%">
            <el-table-column prop="name" :label="t('userAdmin.companies.name')" />
            <el-table-column prop="address" :label="t('userAdmin.companies.address')" />
            <el-table-column prop="zip" :label="t('userAdmin.companies.zip')" />
            <el-table-column prop="city" :label="t('userAdmin.companies.city')" />
            <el-table-column prop="nation" :label="t('userAdmin.companies.nation')" />
            <el-table-column prop="piva" :label="t('userAdmin.companies.piva')" />
            <el-table-column prop="codfis" :label="t('userAdmin.companies.codfis')" />
            <el-table-column prop="codUnico" :label="t('userAdmin.companies.codUnico')" />
            <el-table-column fixed="right">
                <template #default="scope">
                    <el-button type="danger" @click="deletePart(scope.row)" :icon="Delete" circle /></template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>