<script setup lang="ts">
import axios, { AxiosError } from 'axios';
import config from '@/config/config';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/stores/loginStore';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';

const username = ref("");
const password = ref("");
const router = useRouter()
const { t } = useI18n();
const cookieDeclined = localStorage.getItem('vue-cookie-accept-decline-undefined')!='accept';

const loginStore = useLoginStore();

onMounted(() => {
  if (localStorage.getItem('vue-cookie-accept-decline-undefined') == 'decline') {
    ElMessage({
      message: t("login.cookieMessage"),
      type: 'error',
    });
    localStorage.removeItem('vue-cookie-accept-decline-undefined');
  }
});
const login = async () => {
  try {
    const res = await axios.post<string>(config().BASEURL + config().LOGIN, { username: username.value, password: password.value });
    loginStore.updateToken(res.data);
    router.push("/");
    return true;
  }
  catch (e) {
    const err = e as AxiosError;
    if (err?.response?.status == 404) {
      ElMessage({
        message: t("login.wrongPassword"),
        type: 'error',
      })
    } else {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }
  }
}
</script>

<template>
  <main class="w-full max-w-2xl mx-auto p-6">
    <div class="mt-7 border border-gray-200 rounded-xl shadow-sm">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800">{{ $t("login.title") }}</h1>
          <p class="mt-2 text-sm text-gray-600">
            {{ $t("login.noAccount") }}
            <RouterLink :to="{ name: 'register' }" class="text-red-700 decoration-2 hover:underline font-medium">
              {{ $t("login.signup") }}
            </RouterLink>
          </p>
        </div>

        <div class="mt-5" v-if="cookieDeclined">
          <span v-html="t('login.cookieMessage')"></span>
        </div>
        <div class="mt-5" v-else>
          <!-- Form -->
          <form>
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label for="email" class="block text-sm mb-2">{{ $t("login.email") }}</label>
                <input type="email" v-model="username" id="email" name="email"
                  class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500"
                  required aria-describedby="email-error">
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="password" class="block text-sm mb-2">{{ $t("login.password1") }}</label>
                  <RouterLink :to="{ name: 'pwdreset' }"
                    class="text-sm text-red-700 decoration-2 hover:underline font-medium">{{ $t("login.passwordforgot")
                    }}</RouterLink>
                </div>
                <div class="relative">
                  <input type="password" v-model="password" id="password" name="password"
                    class="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                    required aria-describedby="password-error">
                </div>
              </div>
              <!-- End Form Group -->

              <button type="button" @click="login"
                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-800 text-white hover:bg-red-900  transition-all text-sm ">{{
            $t("login.signin") }}</button>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
