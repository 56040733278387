<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useLoginStore } from '@/stores/loginStore';
import { contentStore } from '@/stores/contentStore';

const lStore = useLoginStore();
const cStore = contentStore();
const props = defineProps(['id'])
const cookieDeclined = localStorage.getItem('vue-cookie-accept-decline-undefined')=='decline';

onMounted(async () => {
    try {
        await cStore.loadContent(props.id);
    } catch {
        ;
    }
});
watch(props, () => cStore.loadContent(props.id));

</script>

<template v-if="props.id">
    <div :id="cStore.title[props.id]" class="vContent" :class="{ cookiePolicyDenied: cookieDeclined }">
        <div class="adminTool flex w-full" v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive">
            <RouterLink :to="{ name: 'contentEditor', params: { content: props.id } }">
                <font-awesome-icon :icon="['fas', 'gear']" />
            </RouterLink>
        </div>
        <div v-html="cStore.content[props.id]" class="basis-full"></div>
    </div>
</template>

<style>
.vContent ul {
    list-style-type:  disc !important;
    list-style-position: outside;
    margin-left: 1em;
}

.cookiePolicyDenied iframe {
    display: none;
}
</style>
