<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserContentOrdersStore } from '@/stores/userContentOrdersStore';
import { PaymentType } from '@/models/PaymentType';
import moment from 'moment';

const { t } = useI18n();

const userContentOrdersStore = useUserContentOrdersStore();

onMounted(async () => {
    userContentOrdersStore.load();
});

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("userAdmin.orders.list") }}</div>
            </div>
        </template>
        <el-table :data="userContentOrdersStore.orders" style="width: 100%">
            <el-table-column type="expand">
                <template #default="props">
                    <div class="ml-72">
                        <h3>{{ $t("userAdmin.orders.courses") }}</h3>
                        <el-table :data="props.row.items">
                            <el-table-column :label="t('userAdmin.orders.course')" prop="course.title">
                                <template #default="scope">
                                    {{ scope.row.course.title[$t("key")] }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="t('userAdmin.orders.price')" prop="price.price" />
                            <el-table-column :label="t('userAdmin.orders.partecipant')" prop="partecipant.name">
                                <template #default="scope">
                                    {{ scope.row.partecipant.name }}<br>
                                    <a :href="'mailto:' + scope.row.partecipant.email" target="_blank">{{ scope.row.partecipant.email }}</a>
                                </template>
                            </el-table-column>
                            <el-table-column :label="t('userAdmin.orders.quantity')" prop="quantity" />
                        </el-table>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="header.orderId" :label="t('userAdmin.orders.id')" />
            <el-table-column prop="header.orderDate" :label="t('userAdmin.orders.date')">
                <template #default="scope">
                        {{ moment(scope.row.header.orderDate).format("DD/MM/YYYY") }}
                    </template></el-table-column>
            <el-table-column prop="header.company" :label="t('userAdmin.orders.company')" >
                <template #default="scope">
                    {{ scope.row.header.company?.name }} <br />
                    {{ scope.row.header.company?.address }} <br />
                    {{ scope.row.header.company?.zip }} {{ scope.row.header.company.city }} <br />
                    {{ scope.row.header.company?.piva }}
                </template>
            </el-table-column>
            <el-table-column prop="header.paymentType" :label="t('userAdmin.orders.paymentType')">
                <template #default="props">
                    {{ PaymentType[props.row.header.paymentType] }}
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>