<script setup lang="ts">
import type { Course } from '@/models/course';
import moment from 'moment';
import 'moment/dist/locale/it';
import 'moment/dist/locale/de';
import { useLoginStore } from '@/stores/loginStore';

const lStore = useLoginStore();

defineProps<{
    course: Course
}>();

defineEmits(['click'])

</script>

<template>
    <single-element :image="course.image" :href="{ name: 'courseDetail', params: { course: course.id, lang: 'de' }}">
        <template v-slot:overlay>
            <div class="course-title" v-html="course.title[$t('key')]"></div>
        </template>
        <div class="course-details">
            <span class="course-date block" v-if="course.date">{{ moment(course.date).locale($t("key").toLowerCase()).format("D. MMMM YYYY") }}</span>
            <span class="course-date block">{{ course.timeMorning && course.timeMorning.length == 2 ? moment(course.timeMorning[0]).format("HH:mm") + "-" + moment(course.timeMorning[1]).format("HH:mm"): ""}} {{ course.timeAfternoon && course.timeAfternoon.length == 2 ? moment(course.timeAfternoon[0]).format("HH:mm") + "-" + moment(course.timeAfternoon[1]).format("HH:mm"): ""}}</span>
            <!--<span class="course-maxPartecipants block" v-if="course.maxPartecipants">{{ $t("courseList.maxPartecipants", { nr: course.maxPartecipants}) }}</span>-->

            <!--<span class="course-endregistrationDate block" v-if="course.visualizationDate && moment(course.visualizationDate) > moment()">{{ $t("courseList.endRegistration", { dt:  moment(course.visualizationDate).format("DD.MM.YYYY") }) }}</span>-->
            <span class="course-city block my-3" v-html="course.address[$t('key')]"></span>
            <span class="course-teacher block">{{course.trainer?.name}} {{course.trainer2?.name ? "+": ""}} {{course.trainer2?.name}}</span>
            <div class="adminTool " v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive">
                <RouterLink :to="{ name: 'courseEdit', params: { Id: course.id } }" @click="$emit('click')">
                    <font-awesome-icon :icon="['fas', 'gear']" />
                </RouterLink>
            </div>
        </div>
    </single-element>
</template>