import moment from 'moment';
import 'moment/dist/locale/it';
import 'moment/dist/locale/de';
import { createI18n } from 'vue-i18n';
import type { LocaleMessages, VueMessageType } from 'vue-i18n';

function loadLocaleMessages(): { [key: string]: LocaleMessages<VueMessageType> } {
  const locales: Record<string, { [key: string]: any }> = import.meta.glob('./locales/*.json', {
    eager: true
  })
  const messages: { [key: string]: LocaleMessages<VueMessageType> } = {}
  Object.keys(locales).forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales[key].default
    }
  });
  const loc = localStorage.getItem('locale') ?? 'it';
  moment.locale(loc)
  return messages
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem('locale') || 'it',
  fallbackLocale: 'it',
  messages: loadLocaleMessages()
})
