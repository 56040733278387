<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserContentStore } from '@/stores/userContentStore';
import { Delete } from '@element-plus/icons-vue';
import type { RegisterFormPartecipant } from '@/models/registerFormPartecipant';

const { t } = useI18n();

const userContentStore = useUserContentStore();

onMounted(async () => {
    userContentStore.load();
});

const deletePart = (row: RegisterFormPartecipant) => {
    userContentStore.content.partecipants = userContentStore.content.partecipants.filter(p => !(p.category == row.category && p.name == row.name && p.email == row.email && p.fiscalCode == row.fiscalCode));
    userContentStore.save();
}

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("userAdmin.partecipant.list") }}</div>
            </div>
        </template>
        <el-table :data="userContentStore.content.partecipants" style="width: 100%">
            <el-table-column prop="name" :label="t('userAdmin.partecipant.name')" />
            <el-table-column prop="email" :label="t('userAdmin.partecipant.email')" />
            <el-table-column prop="fiscalCode" :label="t('userAdmin.partecipant.fiscalCode')" />
            <el-table-column prop="category" :label="t('userAdmin.partecipant.category')" />
            <el-table-column fixed="right">
                <template #default="scope">
                    <el-button type="danger" @click="deletePart(scope.row)" :icon="Delete" circle /></template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>