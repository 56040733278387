<script setup lang="ts">
import axios, { AxiosError } from 'axios';
import config from '@/config/config';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/stores/loginStore';
import { ElMessage } from 'element-plus';

const name = ref("");
const router = useRouter()

const loginStore = useLoginStore();
name.value = loginStore.name;

const save = async () => {
  try {
    const res = await axios.post<string>(config().BASEURL + config().UPDATEUSER, { email: loginStore.eMail, name: name.value, roles:[] }, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") } });
    loginStore.updateToken(res.data);
 
    ElMessage({
        message: "User data updated successfully!",
        type: 'success',
      });
    router.push("/");
    return true;
  }
  catch (e) {
    const err = e as AxiosError;
    if (err?.response?.status == 404) {
      ElMessage({
        message: err.response.data as string,
        type: 'error',
      })
    } else {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }
  }
}
</script>

<template>
  <main class="w-full max-w-2xl mx-auto p-6">
    <div class="mt-7 border border-gray-200 rounded-xl shadow-sm">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 mb-10">{{ $t("userSettings.title") }}</h1>

          <form>
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div class="mb-5">
                <label for="email" class="block text-sm mb-2">{{ $t("userSettings.email") }}</label>
                <input type="email" v-model="loginStore.eMail" id="email" name="email"
                  class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500"
                  readonly disabled aria-describedby="email-error">
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div  class="mb-5">
                  <label for="name" class="block text-sm mb-2">{{ $t("userSettings.name") }}</label>
                    <input type="string" v-model="name" id="name" name="name" class="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500" required aria-describedby="email-error">
              </div>
              <!-- End Form Group -->

              <button type="button" @click="save"
                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-800 text-white hover:bg-red-900  transition-all text-sm ">{{
            $t("userSettings.save") }}</button>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
